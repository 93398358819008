// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-development-js": () => import("./../src/pages/development.js" /* webpackChunkName: "component---src-pages-development-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-temp-js": () => import("./../src/pages/temp.js" /* webpackChunkName: "component---src-pages-temp-js" */),
  "component---src-pages-work-common-project-status-bar-index-jsx": () => import("./../src/pages/work/common/ProjectStatusBar/index.jsx" /* webpackChunkName: "component---src-pages-work-common-project-status-bar-index-jsx" */),
  "component---src-pages-work-kiosk-venue-project-index-js": () => import("./../src/pages/work/KioskVenueProject/index.js" /* webpackChunkName: "component---src-pages-work-kiosk-venue-project-index-js" */),
  "component---src-pages-work-lmj-index-js": () => import("./../src/pages/work/LMJ/index.js" /* webpackChunkName: "component---src-pages-work-lmj-index-js" */),
  "component---src-pages-work-pub-sub-manager-index-js": () => import("./../src/pages/work/PubSubManager/index.js" /* webpackChunkName: "component---src-pages-work-pub-sub-manager-index-js" */),
  "component---src-pages-work-tacorrido-index-js": () => import("./../src/pages/work/Tacorrido/index.js" /* webpackChunkName: "component---src-pages-work-tacorrido-index-js" */)
}

